<template>
    <div id="contato" :style="{ opacity: isLoading ? 0.5 : 1 }">
        <FormIntro />
        <form class="form-group" id="email-form" @submit.prevent="submit">
            <div class="form-group">
                <SelectField
                    v-model="formData.assunto"
                    :label="formFields.assunto[this.tenant][0].label"
                    :error.sync="errors.assunto"
                >
                    <option
                        v-for="option in formFields.assunto[this.tenant][0].options"
                        :key="option.value"
                        :value="option"
                    >
                        {{ option.text }}
                    </option>
                </SelectField>
                <SelectField
                    v-show="formData.assunto.subcategorias"
                    v-model="formData.subcategoria"
                    :error.sync="errors.subcategoria"
                    label="Selecione a opção que melhor descreve sua situação:"
                    class="mt-3"
                >
                    <option
                        v-for="category in formData.assunto.subcategorias"
                        :key="category.id"
                        :value="category.text"
                    >
                        {{ category.text }}
                    </option>
                </SelectField>

                <div class="mt-2" v-if="formData.assunto.url">
                    <span>Sua dúvida pode estar em</span>
                    <a :href="formData.assunto.url" target="_blank" class="link-helpcenter">
                        <span>{{ formData.assunto.message }}</span>
                    </a>
                </div>

                <SelectField
                    v-show="units && units.length > 0"
                    v-model="formData.filial"
                    label="Selecione a filial da sua escola:"
                    class="mt-3"
                >
                    <option v-for="unit in units" :key="unit.id" :value="unit.name">
                        {{ unit.name }}
                    </option>
                </SelectField>
            </div>
            <div class="form-group" v-for="input in formFields.textInputs">
                <TextField
                    v-if="input.name !== `mensagem`"
                    :name="input.name"
                    v-model="formData[input.name]"
                    :error="input.isRequired ? errors[input.name] : null"
                    :label="input.label"
                    :type="input.name"
                    maxlength="128"
                />
                <TextAreaField
                    v-else
                    :name="input.name"
                    v-model="formData[input.name]"
                    :error="errors[input.name]"
                    :label="input.label"
                    :type="input.name"
                />
            </div>
            <span
                ><i>Para agilizar seu atendimento, descreva sua solicitação detalhadamente. :) </i>
            </span>
            <Checkbox
                v-if="tenant === `chicorei`"
                v-for="input in formFields.checkboxInputs"
                :key="input.name"
                v-model="formData[input.name]"
                class="mb-0 mt-0"
                :label="input.label"
                :name="input.name"
            />
            <br />
            <button id="btn-submit-emailform" @click="submit" class="btn">Enviar</button>
        </form>
        <Captcha v-model="captchaResponse" ref="recaptcha" @error="showCaptchaError" />
    </div>
</template>

<script>
    import FormCaptcha from './FormCaptcha.vue';
    import FormIntro from './FormIntro.vue';
    import Checkbox from '../../input/checkbox/Checkbox.vue';
    import Captcha from '../captcha/Captcha.vue';
    import SelectField from '../../input/select-field/SelectField.vue';
    import TextField from '../../input/text-field/TextField.vue';
    import TextAreaField from '../../input/text-area-field/TextAreaField.vue';

    export default {
        name: 'EmailForm',
        components: {
            TextAreaField,
            TextField,
            SelectField,
            Captcha,
            FormIntro,
            Checkbox,
            FormCaptcha,
        },

        props: {
            apiErrors: {
                type: Object,
            },
            units: {
                type: Array,
                required: false,
            },
        },

        data() {
            return {
                formFields: {
                    textInputs: [
                        {
                            label: 'Nome *',
                            name: 'nome',
                            isRequired: true,
                        },
                        {
                            label: 'E-mail *',
                            name: 'email',
                            isRequired: true,
                        },
                        {
                            label: 'CPF *',
                            name: 'cpf',
                            isRequired: true,
                        },
                        {
                            label: 'Número do Pedido',
                            name: 'pedido',
                            isRequired: false,
                        },
                        {
                            label: 'Mensagem *',
                            name: 'mensagem',
                            isRequired: true,
                        },
                    ],
                    checkboxInputs: [
                        {
                            label: 'Receber novidades e promoções por email',
                            name: 'newsletter',
                            isRequired: false,
                        },
                    ],

                    assunto: {
                        chicorei: [
                            {
                                label: 'Assunto *',
                                options: [
                                    {
                                        id: 1,
                                        text: 'Alteração no pedido',
                                        value: 'alteracao-no-pedido',
                                        subcategorias: [
                                            {
                                                id: 1,
                                                text: 'Alteração de cor',
                                            },
                                            {
                                                id: 2,
                                                text: 'Alteração de tamanho e/ou modelagem',
                                            },
                                            {
                                                id: 3,
                                                text: 'Alteração do endereço de entrega',
                                            },
                                        ],
                                    },
                                    {
                                        id: 2,
                                        text: 'Cancelamento',
                                        value: 'cancelamento',
                                    },
                                    {
                                        id: 3,
                                        text: 'Dúvida',
                                        value: 'duvida',
                                        message: 'Central de Ajuda',
                                        url: '/central-de-ajuda',
                                        subcategorias: [
                                            {
                                                id: 1,
                                                text: 'Cadastro no site',
                                            },
                                            {
                                                id: 2,
                                                text: 'Pagamento',
                                            },
                                            {
                                                id: 3,
                                                text: 'Prazo de produção',
                                            },
                                            {
                                                id: 4,
                                                text: 'Promoção',
                                            },
                                            {
                                                id: 5,
                                                text: 'Uso de cupons',
                                            },
                                            {
                                                id: 6,
                                                text: 'Outro',
                                            },
                                        ],
                                    },
                                    {
                                        id: 4,
                                        text: 'Entrega',
                                        value: 'entrega',
                                        message: 'Prazos e Envio',
                                        url: '/central-de-ajuda/#reamaze#0#/kb/envio',
                                        subcategorias: [
                                            {
                                                id: 1,
                                                text: 'Rastreamento do pedido',
                                            },
                                            {
                                                id: 2,
                                                text: 'Pedido Atrasado',
                                            },
                                            {
                                                id: 3,
                                                text: 'Pedido extraviado',
                                            },
                                            {
                                                id: 4,
                                                text: 'Pedido consta como entregue, mas não foi recebido',
                                            },
                                            {
                                                id: 5,
                                                text: 'Alteração do endereço de entrega após o envio do pedido',
                                            },
                                            {
                                                id: 6,
                                                text: 'Problemas com a transportadora',
                                            },
                                            {
                                                id: 7,
                                                text: 'O pedido foi devolvido ao remetente',
                                            },
                                            {
                                                id: 8,
                                                text: 'Pedido aguardando retirada nos Correios',
                                            },
                                            {
                                                id: 9,
                                                text: 'Destinatário ausente',
                                            },
                                        ],
                                    },
                                    {
                                        id: 5,
                                        text: 'Troca e Devolução',
                                        value: 'troca-e-devolucao',
                                        message: 'Trocas e Devoluções',
                                        url: '/central-de-ajuda/#reamaze#0#/kb/trocas-devolucoes',
                                        subcategorias: [
                                            {
                                                id: 1,
                                                text: 'Dúvidas sobre o processo de troca ou devolução',
                                            },
                                            {
                                                id: 2,
                                                text: 'Recebi o produto errado / Não recebi o produto',
                                            },
                                            {
                                                id: 3,
                                                text: 'Troca por defeito',
                                            },
                                            {
                                                id: 4,
                                                text: 'Troca por tamanho',
                                            },
                                            {
                                                id: 5,
                                                text: 'Troca por cupom',
                                            },
                                            {
                                                id: 6,
                                                text: 'Devolução',
                                            },
                                        ],
                                    },
                                    {
                                        id: 6,
                                        text: 'Pedido atrasado',
                                        value: 'pedido-atrasado',
                                        subcategorias: [
                                            {
                                                id: 1,
                                                text: 'O pedido ainda não foi enviado',
                                            },
                                            {
                                                id: 2,
                                                text: 'O pedido já foi enviado',
                                            },
                                        ],
                                    },
                                    {
                                        id: 7,
                                        text: 'Reclamação',
                                        value: 'reclamacao',
                                        subcategorias: [
                                            {
                                                id: 1,
                                                text: 'Problemas de qualidade',
                                            },
                                            {
                                                id: 2,
                                                text: 'Prazo de produção',
                                            },
                                            {
                                                id: 3,
                                                text: 'Outro motivo',
                                            },
                                        ],
                                    },
                                    {
                                        id: 8,
                                        text: 'Sugestão',
                                        value: 'sugestao',
                                    },
                                    {
                                        id: 9,
                                        text: 'Elogio',
                                        value: 'elogio',
                                    },
                                    {
                                        text: 'Outro',
                                        value: 'outro',
                                        message: 'Central de Ajuda',
                                        url: '/central-de-ajuda/',
                                    },
                                ],
                                url: {
                                    duvida: '/central-de-ajuda',
                                    'troca-e-devolucao':
                                        '/central-de-ajuda/#reamaze#0#/kb/trocas-devolucoes',
                                    entrega: '/central-de-ajuda/#reamaze#0#/kb/envio',
                                    outro: '/central-de-ajuda/',
                                },
                                isRequired: true,
                            },
                        ],
                        umapenca: [
                            {
                                label: 'Assunto *',
                                options: [
                                    {
                                        id: 1,
                                        text: 'Cancelamento',
                                        value: 'cancelamento',
                                    },
                                    {
                                        id: 2,
                                        text: 'Dúvida',
                                        value: 'duvida',
                                        message: 'Central de Ajuda',
                                        url: '/central-de-ajuda',
                                        subcategorias: [
                                            {
                                                id: 1,
                                                text: 'Cadastro no site',
                                            },
                                            {
                                                id: 2,
                                                text: 'Pagamento',
                                            },
                                            {
                                                id: 3,
                                                text: 'Prazo de produção',
                                            },
                                            {
                                                id: 4,
                                                text: 'Promoção',
                                            },
                                            {
                                                id: 5,
                                                text: 'Uso de cupons',
                                            },
                                            {
                                                id: 6,
                                                text: 'Outro',
                                            },
                                        ],
                                    },
                                    {
                                        id: 3,
                                        text: 'Entrega',
                                        value: 'entrega',
                                        message: 'Prazos e Envio',
                                        subcategorias: [
                                            {
                                                id: 1,
                                                text: 'Pedido atrasado',
                                            },
                                            {
                                                id: 2,
                                                text: 'Pedido extraviado',
                                            },
                                            {
                                                id: 3,
                                                text: 'Pedido consta como entregue, mas não foi recebido',
                                            },
                                            {
                                                id: 4,
                                                text: 'Alteração do endereço de entrega após o envio do pedido',
                                            },
                                            {
                                                id: 5,
                                                text: 'Problemas com a transportadora',
                                            },
                                            {
                                                id: 6,
                                                text: 'Rastreamento do pedido',
                                            },
                                            {
                                                id: 7,
                                                text: 'O pedido foi devolvido ao remetente',
                                            },
                                            {
                                                id: 8,
                                                text: 'Pedido aguardando retirada nos Correios',
                                            },
                                            {
                                                id: 9,
                                                text: 'Destinatário ausente',
                                            },
                                        ],
                                    },
                                    {
                                        id: 4,
                                        text: 'Devolução',
                                        value: 'devolucao',
                                    },
                                    {
                                        id: 5,
                                        text: 'Troca por defeito / Recebi o produto errado',
                                        value: 'troca-defeito-produto-errado',
                                        subcategorias: [
                                            {
                                                id: 1,
                                                text: 'Troca por defeito',
                                            },
                                            {
                                                id: 2,
                                                text: 'Recebi o produto errado',
                                            },
                                            {
                                                id: 3,
                                                text: 'Não recebi o produto',
                                            },
                                        ],
                                    },
                                    {
                                        id: 6,
                                        text: 'Pedido atrasado',
                                        value: 'pedido-atrasado',
                                        subcategorias: [
                                            {
                                                id: 1,
                                                text: 'O pedido ainda não foi enviado',
                                            },
                                            {
                                                id: 2,
                                                text: 'O pedido já foi enviado',
                                            },
                                        ],
                                    },
                                    {
                                        id: 7,
                                        text: 'Minha loja em Uma Penca',
                                        value: 'minha-loja',
                                        subcategorias: [
                                            {
                                                id: 1,
                                                text: 'Como criar uma loja',
                                            },
                                            {
                                                id: 2,
                                                text: 'Cadastro de dados bancários',
                                            },
                                            {
                                                id: 3,
                                                text: 'Saque',
                                            },
                                            {
                                                id: 4,
                                                text: 'Minha arte foi recusada',
                                            },
                                            {
                                                id: 5,
                                                text: 'Outro',
                                            },
                                        ],
                                    },
                                    {
                                        id: 8,
                                        text: 'Reclamação',
                                        value: 'reclamacao',
                                        subcategorias: [
                                            {
                                                id: 1,
                                                text: 'Problemas de qualidade',
                                            },
                                            {
                                                id: 2,
                                                text: 'Prazo de produção',
                                            },
                                            {
                                                id: 3,
                                                text: 'Outro motivo',
                                            },
                                        ],
                                    },
                                    {
                                        id: 9,
                                        text: 'Sugestão',
                                        value: 'sugestao',
                                    },
                                    {
                                        id: 11,
                                        text: 'Elogio',
                                        value: 'elogio',
                                    },
                                    {
                                        id: 11,
                                        text: 'Outro',
                                        value: 'outro',
                                        message: 'Central de Ajuda',
                                        url: '/central-de-ajuda/',
                                    },
                                ],
                                url: {
                                    duvida: '/central-de-ajuda',
                                    'troca-e-devolucao':
                                        '/central-de-ajuda/#reamaze#0#/kb/trocas-devolucoes',
                                    entrega: '/central-de-ajuda/#reamaze#0#/kb/envio',
                                    outro: '/central-de-ajuda/',
                                },
                                isRequired: true,
                            },
                        ],
                        maisuni: [
                            {
                                label: 'Assunto *',
                                options: [
                                    {
                                        id: 1,
                                        text: 'Dúvida',
                                        value: 'duvida',
                                        message: 'Central de Ajuda',
                                        url: '/central-de-ajuda',
                                    },
                                    {
                                        id: 2,
                                        text: 'Troca',
                                        value: 'troca',
                                    },
                                    {
                                        id: 3,
                                        text: 'Elogio',
                                        value: 'elogio',
                                    },
                                    {
                                        id: 4,
                                        text: 'Sugestão',
                                        value: 'sugestao',
                                    },
                                    {
                                        id: 5,
                                        text: 'Reclamação',
                                        value: 'reclamacao',
                                    },
                                    {
                                        id: 6,
                                        text: 'Pedidos',
                                        value: 'minha-loja',
                                    },
                                    {
                                        id: 11,
                                        text: 'Outro',
                                        value: 'outro',
                                        message: 'Central de Ajuda',
                                        url: '/central-de-ajuda/',
                                    },
                                ],
                                url: {
                                    duvida: '/central-de-ajuda',
                                    outro: '/central-de-ajuda/',
                                },
                                isRequired: true,
                            },
                        ],
                    },
                },
                formData: {
                    assunto: '',
                    subcategoria: '',
                    nome: '',
                    email: '',
                    cidade: '',
                    filial: null,
                    cpf: '',
                    pedido: '',
                    mensagem: '',
                    newsletter: false,
                    recaptcha: null,
                },
                formStatus: {},
                isLoading: null,
                errors: {
                    assunto: null,
                    subcategoria: null,
                    nome: null,
                    email: null,
                    cidade: null,
                    filial: '',
                    cpf: null,
                    pedido: null,
                    mensagem: null,
                },
                captchaResponse: null,
                viaLeadster: false,
                tenant: this.$scope.TENANT_IDENTIFIER,
            };
        },

        created() {
            const url = new URL(window.location.href);
            const currentVia = url.searchParams.get('v');
            if (currentVia) {
                if (currentVia === 'l') {
                    this.viaLeadster = true;
                }
                url.searchParams.delete('v');
                window.history.replaceState('', '', url.toString());
            }

            if (this.tenant === 'umapenca') {
                this.formFields.textInputs.splice(2, 0, {
                    label: 'Cidade/UF',
                    name: 'cidade',
                    isRequired: false,
                });
            }
        },

        watch: {
            captchaResponse(to) {
                if (to) {
                    this.submit();
                }
            },

            apiErrors(newApiErrors) {
                this.clearErrors();
                this.handleApiErrors(newApiErrors);
            },
        },

        methods: {
            clearErrors() {
                for (const prop in this.errors) {
                    if (this.errors.hasOwnProperty(prop)) {
                        this.$set(this.errors, prop, null);
                    }
                }
            },

            handleApiErrors(errors) {
                if (errors.nome) {
                    this.$set(this.errors, 'nome', errors.nome[0]);
                }

                if (errors.email) {
                    this.$set(this.errors, 'email', errors.email[0]);
                }

                if (errors.mensagem) {
                    this.$set(this.errors, 'mensagem', errors.mensagem[0]);
                }
            },

            showCaptchaError(message) {
                this.$emit('error', message);
            },

            isStringEmpty(str) {
                return !str || str.trim().length === 0;
            },

            validateRequiredField(name) {
                if (this.isStringEmpty(this.formData[name])) {
                    this.$set(this.errors, name, 'Campo obrigatório');
                }
            },

            validate() {
                this.validateRequiredField('nome');
                this.validateRequiredField('email');
                this.validateRequiredField('cpf');
                this.validateRequiredField('mensagem');

                for (const prop in this.errors) {
                    if (this.errors.hasOwnProperty(prop)) {
                        if (this.errors[prop] !== null) return false;
                    }
                }

                return true;
            },

            submit() {
                this.clearErrors();

                if (this.validate()) {
                    this.isLoading = true;
                    this.formStatus = new FormData();
                    this.formStatus.append('assunto', this.formData.assunto.text);
                    this.formStatus.append('subcategoria', this.formData.subcategoria);
                    this.formStatus.append('nome', this.formData.nome);
                    this.formStatus.append('email', this.formData.email);
                    this.formStatus.append('cpf', this.formData.cpf);
                    this.formStatus.append('cidade', this.formData.cidade);
                    if (this.tenant === 'maisuni') {
                        this.formStatus.append(
                            'escola',
                            this.tenant === 'maisuni' ? this.$scope.STORE.name : null
                        );
                        this.formStatus.append('filial', this.formData.filial);
                    }
                    this.formStatus.append('pedido', this.formData.pedido);

                    this.formStatus.append('mensagem', this.formData.mensagem);
                    this.formStatus.append('newsletter', this.formData.newsletter);
                    this.formStatus.append('via_leadster', this.viaLeadster ? 1 : 0);
                    if (!this.captchaResponse) {
                        this.$refs.recaptcha.executeRecaptcha();
                        return;
                    }
                    this.formStatus.append('recaptcha', this.captchaResponse);
                    this.$refs.recaptcha.resetRecaptcha();

                    this.$emit('done', this.formStatus);
                    this.isLoading = false;
                }
            },
        },
    };
</script>
